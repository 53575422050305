import "../css/footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="foot_img">
        <img src={process.env.PUBLIC_URL + "/images/PIGGZ1.png"} alt="" />
      </div>

      <div className="foot_cont">
        <p className="p_email">kontakt@pigart.pl</p>
        <p>
          <a href="https://www.facebook.com/pigartnew">
            <img src={process.env.PUBLIC_URL + "/images/fbIcon.png"} alt="" />
          </a>
        </p>
        <p>
          <a href="https://www.instagram.com/pigart1/">
            <img src={process.env.PUBLIC_URL + "/images/igIcon.png"} alt="" />
          </a>
        </p>
      </div>
    </footer>
  );
};
export default Footer;
