import React, { useContext, useState } from "react";

import { GalleryContext } from "./GalleryContext";

import "../css/home.css";

const Home = (props) => {
  const { galery } = useContext(GalleryContext);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  // console.log(props.lng);
  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <div className="gallery">
        {galery.map((item) => (
          <div className="gallery-item" key={item.filename} id={item.id}>
            <img
              src={`${item.filename}`}
              alt=""
              onClick={() => handleImageClick(item.filename)}
            />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="modal" onClick={handleCloseModal}>
          <span className="close">&times;</span>
          <img className="modal-content" src={selectedImage} alt="" />
        </div>
      )}
    </div>
  );
};
export default Home;
