import React, { useContext, useState } from "react";

import { GalleryContext } from "./GalleryContext";
import "../css/freetime.css";

const Freetime = () => {
  const { freetime } = useContext(GalleryContext);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="freetime_cont">
      <div className="freeTime_gallery">
        {freetime.map((item) =>
          item.category === "Freetime" ? (
            <div className="gallery-item" key={item.filename} id={item.id}>
              <img
                src={`${item.filename}`}
                alt=""
                onClick={() => handleImageClick(item.filename)}
              />
            </div>
          ) : null
        )}
      </div>
      {selectedImage && (
        <div className="modal" onClick={handleCloseModal}>
          <span className="close">&times;</span>
          <img className="modal-content" src={selectedImage} alt="" />
        </div>
      )}
    </div>
  );
};
export default Freetime;
