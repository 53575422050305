import React from "react";
import { useLocation } from "react-router";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";

import "../css/header.css";

const Header = () => {
  const loaction = useLocation();
  const { t } = useTranslation();
  return (
    <header>
      <div style={loaction.pathname === "/" ? null : { display: "none" }}>
        <Marquee className="marquee" speed={110}>
          <p>
            <i>{t("marque.grafika")}</i>
            <span>{t("marque.malarstwo")}</span>
            <i>{t("marque.sztuka")}</i>
            {t("marque.reklama")}
            <i>{t("marque.rysunek")}</i>
            <span>{t("marque.kolor")}</span>
            <i>{t("marque.print")}</i>
          </p>
        </Marquee>
        <Marquee className="marquee">
          <p>
            <i>{t("marque.szczecin")}</i>
            <span>{t("marque.projektowanie")}</span>
            <i>{t("marque.polska")}</i>
            {t("marque.aranżacje")}
            <span>
              {t("marque.art")}
              {t("marque.graphic")}
            </span>
          </p>
        </Marquee>
        <Marquee speed={80} className="marquee">
          <p>
            <i>{t("marque.druk")}</i>
            <span>
              {t("marque.poligrafia")}
              <i>{t("marque.portfolio")}</i>
            </span>
            {t("marque.identyfikacja")}
            <span>
              <i>{t("marque.plakat")}</i>
            </span>
            {t("marque.ilustracja")}
          </p>
        </Marquee>
      </div>
    </header>
  );
};

export default Header;
