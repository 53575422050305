import React, { useState, useRef } from "react";
import { v4 as uuid } from "uuid";

const AdnimForm = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [imageLink, setImageLink] = useState();
  const [validationError, setValidationError] = useState();
  const [category, setCategory] = useState();

  const fileInputRef = useRef();

  document.addEventListener("DOMContentLoaded", () => {
    const form = document.getElementById("uploadForm");

    form.addEventListener("submit", (event) => {
      event.preventDefault();
      handleUpload();
    });
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedExtensions = [".jpg", ".jpeg", ".png", ".gif"];
      const selectedFileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes("." + selectedFileExtension)) {
        setValidationError(
          "Invalid file type. Select an image file (png, jpg, jpeg, gif)"
        );
        setSelectedFile(null);
        fileInputRef.current.value = "";
      } else {
        setSelectedFile(file);
        setValidationError("");
      }
    }
  };

  const handleUpload = async () => {
    const fileInput = document.getElementById("fileInput");
    const selectedFile = fileInput.files[0];

    if (selectedFile && category) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("category", category);
      formData.append("id", uuid());

      try {
        const response = await fetch("/api/upload.php", {
          method: "POST",
          body: formData,
        });

        const text = await response.text();
        try {
          const data = JSON.parse(text);
          if (data.error) {
            console.error("Error:", data.error);
          } else {
            setImageLink(data.image_link);
          }
        } catch (jsonError) {
          console.error("JSON parsing error:", jsonError);
          console.error("Response text:", text);
        }

        fileInput.value = "";
      } catch (error) {
        console.error("Fetch error:", error);
      }
    } else {
      console.error("Please select a file to upload");
    }
  };

  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  const AdminPanel = () => (
    <div>
      <h1>Witaj</h1>
      <p>wybierz jedno zdjęcie i kategorię odpowiadającą mu</p>
      <div>
        <form id="uploadForm" encType="multipart/form-data">
          <input
            type="file"
            id="fileInput"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          <select name="category" onChange={(e) => setCategory(e.target.value)}>
            <option></option>
            <option value="Reklama">Reklama</option>
            <option value="Freetime">Freetime</option>
            <option value="Sztuka">Sztuka</option>
          </select>

          <button type="button" onClick={handleUpload}>
            Upload
          </button>
        </form>
      </div>
      <div>
        {validationError && <p style={{ color: "red" }}>{validationError}</p>}
        {imageLink && <img src={imageLink} alt="Uploaded" />}
      </div>
    </div>
  );

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Logika logowania
    if (password === "haslo123") {
      setLoggedIn(true);
    } else {
      alert("Nieprawidłowe hasło");
    }
  };

  return (
    <div>
      {!loggedIn ? (
        <form onSubmit={handleSubmit}>
          <label>
            Hasło:
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </label>
          <button type="submit">Zaloguj się</button>
        </form>
      ) : (
        <AdminPanel />
      )}
    </div>
  );
};

export default AdnimForm;
