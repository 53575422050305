import React, { useContext, useState } from "react";
import { motion } from "framer-motion";

import { GalleryContext } from "./GalleryContext";
import "../css/advertising.css";

const Advertising = () => {
  const { advertising } = useContext(GalleryContext);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="advertising_cont">
      <div className="advertising_gallery">
        {advertising.map((item) =>
          item.category === "Reklama" ? (
            <div className="gallery-item" key={item.filename} id={item.id}>
              <img
                src={`${item.filename}`}
                alt=""
                onClick={() => handleImageClick(item.filename)}
              />
            </div>
          ) : null
        )}
      </div>
      {selectedImage && (
        <div className="modal" onClick={handleCloseModal}>
          <span className="close">&times;</span>
          <img className="modal-content" src={selectedImage} alt="" />
        </div>
      )}
    </div>
  );
};
export default Advertising;
