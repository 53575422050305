import React, { useEffect } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "./footer";
import Header from "./header";
import Home from "./home";
import Nav from "./nav";

import ArtPage from "./artpage";
import AdnimForm from "./adminForm";
import About from "./about";
import Contact from "./contact";
import Advertising from "./advertising";
import Freetime from "./freetime";

import "../css/App.css";

const App = () => {
  const { t, i18n } = useTranslation();
  const lng = null;
  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);

  return (
    <div className="App">
      <Router>
        <Nav />
        <Header />
        <Routes>
          <Route path="/" element={<Home lng={lng} />} />
          <Route
            path="/advertising"
            element={<Advertising t={t.Advertising} />}
          />
          <Route path="/artPage" element={<ArtPage t={t.ArtPage} />} />
          <Route path="/freetime" element={<Freetime t={t.Freetime} />} />
          <Route path="/about" element={<About t={t.About} />} />
          <Route path="/contact" element={<Contact t={t.Contact} />} />
          <Route path="/adminform" element={<AdnimForm t={t} />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
