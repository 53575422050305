import { motion } from "framer-motion";

import "../css/contact.css";

const Contact = () => {
  return (
    <div className="contact_cont">
      <div className="contact_content">
        <div className="content_element">
          <p>
            Email: <span>kontakt@pigart.pl</span>
          </p>
          <p>
            Tel: <span>+48 794 777 380</span>
          </p>
          <p>
            <a href="https://www.facebook.com/pigartnew">Facebook</a>
          </p>
          <p>
            <a href="https://www.instagram.com/pigart1/">Instagram</a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Contact;
