import React, { createContext, useState, useEffect } from "react";

export const GalleryContext = createContext();

export const GalleryProvider = ({ children }) => {
  const [galery, setGalery] = useState([]);
  const [freetime, setFreetime] = useState([]);
  const [advertising, setAvertising] = useState([]);
  const [art, setArt] = useState([]);

  const fetchData = async () => {
    const uploadData = await fetch("/uploads.json");
    const data = await uploadData.json();

    const rekData = data
      .filter((item) => item.category === "Reklama")
      .slice(-5)
      .reverse();

    const freeData = data
      .filter((item) => item.category === "Freetime")
      .slice(-1)
      .reverse();

    const sztData = data
      .filter((item) => item.category === "Sztuka")
      .slice(-3)
      .reverse();

    setFreetime(data.filter((item) => item.category === "Freetime").reverse());
    setAvertising(data.filter((item) => item.category === "Reklama").reverse());
    setArt(data.filter((item) => item.category === "Sztuka").reverse());

    const elements = [
      rekData[0] ?? null,
      sztData[0] ?? null,
      rekData[1] ?? null,
      sztData[1] ?? null,
      freeData[0] ?? null,
      sztData[2] ?? null,
      rekData[2] ?? null,
      rekData[3] ?? null,
      rekData[4] ?? null,
    ].filter((item) => item !== null);

    setGalery(elements);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <GalleryContext.Provider
      value={{ galery, setGalery, fetchData, freetime, advertising, art }}>
      {children}
    </GalleryContext.Provider>
  );
};
