import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
// import { useMediaQuery } from "react-responsive";
import { FaBars, FaTimes } from "react-icons/fa";
// import { CiLock } from "react-icons/ci";

import { useTranslation } from "react-i18next";

import "../css/nav.css";

const Nav = (props) => {
  const { t } = useTranslation();

  const navData = [
    { id: 1, text: t("nav.Advertising"), to: "/advertising" },
    { id: 2, text: t("nav.Art"), to: "/artPage" },
    { id: 3, text: t("nav.Freetime"), to: "/freetime" },
    { id: 4, text: t("nav.About"), to: "/about" },
    { id: 5, text: t("nav.Contact"), to: "/contact" },
  ];

  const [active, setActive] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [navs, setNavs] = useState(navData);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const viewCheck = (e) => {
    if (!isMobile) {
      let posistion = window.scrollY;
      if (posistion > 330) {
        setActive(true);
      } else if (posistion < 330) {
        setActive(false);
      }
    }
  };

  const NavsLinks = () => (
    <ul>
      {navs.map((nav) => (
        <motion.li
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.2 }}
          key={nav.id}>
          <Link to={nav.to}>{nav.text}</Link>
        </motion.li>
      ))}
    </ul>
  );

  const Navigation = () => (
    <motion.div
      className="navsLinks"
      duration={0.5}
      animate={active ? { height: "75px" } : { height: "100px" }}>
      <Link to="/">
        <motion.div
          className="img-nav"
          animate={
            active
              ? { width: "220px", height: "80px" }
              : { width: "300px", height: "100px" }
          }>
          <img src={process.env.PUBLIC_URL + "/images/PIGGZ2.png"} alt="" />
        </motion.div>
      </Link>
      <motion.div
        duration={0.5}
        animate={
          active
            ? { opacity: 0, transform: "translateY(-20px)" }
            : { opacity: 1, transform: "translateY(0px)" }
        }
        className="social-nav">
        <a href="https://www.facebook.com/pigartnew">facebook </a>
        <a href="https://www.instagram.com/pigart1/">Instagram</a>
        <span>+48 793 777 380</span>
        {/* <span>
          <Link to="/adminform">
            <CiLock />
          </Link>
        </span> */}
      </motion.div>
      <motion.div
        insert
        // initial={{ transform: "translateY(20px)" }}
        style={
          active
            ? { transform: "translateY(-40px)" }
            : { transform: "translateY(0px)" }
        }
        animate={active ? { fontSize: "20px" } : { fontSize: "30px" }}
        duration={0.5}
        className="navigation">
        <ul>
          <NavsLinks />
        </ul>
      </motion.div>
    </motion.div>
  );

  const MobileNavigation = () => {
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
      setShowModal(!showModal);
    };

    const handleBarsIconClick = () => {
      toggleModal();
    };

    const modalVariants = {
      hidden: {
        y: "-100vh",
      },
      visible: {
        y: 0,
        transition: {
          type: "tween", // Set transition type to 'tween'
          duration: 0.3, // Specify duration
        },
      },
      exit: {
        y: "-100vh",
        transition: {
          type: "tween",
          duration: 0.3,
          delay: 0.3,
        },
      },
    };

    const linkItemVariants = {
      hidden: { opacity: 0, y: "50%" },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
          ease: "easeOut", // Add ease-out easing function
        },
      },
      exit: {
        opacity: 0,
        y: "50%",
        transition: {
          duration: 0.1,
          ease: "easeOut", // Add ease-out easing function
        },
      },
    };

    const navLinksVariants = {
      hidden: {},
      visible: {
        transition: {
          staggerChildren: 0.1,
          delayChildren: 0.3,
        },
      },
      exit: {
        transition: {
          staggerChildren: 0.05,
          staggerDirection: -1,
        },
      },
    };

    return (
      <div className="mobileNavsLinks">
        <div className="mobileNavsLinkscontainer mx-auto flex justify-between items-center ">
          <div className="logo">
            <Link to="/">
              <img src={process.env.PUBLIC_URL + "/images/PIGGZ2.png"} alt="" />
            </Link>
          </div>
          <div className="bar">
            <FaBars onClick={handleBarsIconClick} />
          </div>
        </div>
        <AnimatePresence>
          {showModal && (
            <motion.div
              className="showCont"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit">
              <FaTimes
                className="close"
                onClick={toggleModal}
                style={{ fontSize: "40px" }}
              />
              <motion.div
                className="mobileContentConteiner"
                variants={navLinksVariants}
                initial="hidden"
                animate="visible"
                exit="exit">
                <div className="mobileContent">
                  {navData.map((link) => (
                    <motion.span
                      key={link.id}
                      variants={linkItemVariants}
                      onClick={toggleModal}>
                      <Link to={link.to}>{link.text}</Link>
                    </motion.span>
                  ))}
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", viewCheck);
    return () => {
      window.removeEventListener("scroll", viewCheck);
    };
  }, []);

  return <nav>{isMobile ? <MobileNavigation /> : <Navigation />}</nav>;
};

export default Nav;
