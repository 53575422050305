import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Advertising from "./advertising";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "pl",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      pl: {
        translation: {
          nav: {
            Advertising: "Reklama",
            Art: "Sztuka",
            Freetime: "Czas wolny",
            About: "O mnie",
            Contact: "Kontakt",
          },
          marque: {
            grafika: "grafika",
            malarstwo: "malarstwo",
            sztuka: "sztuka",
            reklama: "reklama",
            rysunek: "rysunek",
            kolor: "kolor",
            print: "print",
            szczecin: "szczecin",
            projektowanie: "projektowanie",
            polska: "polska",
            aranżacje: "aranżacje",
            art: "art",
            graphic: "graphic",
            druk: "druk",
            poligrafia: "poligrafia",
            portfolio: "portfolio",
            identyfikacja: "identyfikacja",
            plakat: "plakat",
            ilustracja: "ilustracja",
          },
        },
      },
      de: {
        translation: {
          nav: {
            Advertising: "Werbung",
            Art: "Kunst",
            Freetime: "Freie Zeit",
            About: "Über mich",
            Contact: "Kontakt",
          },
          marque: {
            grafika: "Grafik",
            malarstwo: "Malerei",
            sztuka: "Kunst",
            reklama: "Werbung",
            rysunek: "Zeichnung",
            kolor: "Farbe",
            print: "drucken",
            szczecin: "szczecin",
            projektowanie: "Entwurf",
            polska: "polen",
            aranżacje: "Arrangements",
            art: "Kunst",
            graphic: "Grafik",
            druk: "drucken",
            poligrafia: "dtp",
            portfolio: "Portfolio",
            identyfikacja: "Identifizierung",
            plakat: "Poster",
            ilustracja: "Abbildung",
          },
        },
      },
      en: {
        translation: {
          nav: {
            Advertising: "Advertising",
            Art: "Art",
            Freetime: "Freetime",
            About: "About",
            Contact: "Contact",
          },
          marque: {
            grafika: "graphic",
            malarstwo: "painting",
            sztuka: "art",
            reklama: "advertising",
            rysunek: "drawing",
            kolor: "color",
            print: "print",
            szczecin: "szczecin",
            projektowanie: "design",
            polska: "poland",
            aranżacje: "arrangements",
            art: "art",
            graphic: "graphic",
            druk: "print",
            poligrafia: "dtp",
            portfolio: "Portfolio",
            identyfikacja: "identification",
            plakat: "poster",
            ilustracja: "illustration",
          },
        },
      },
    },
  });

export default i18n;
